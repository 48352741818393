/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { Text, Link } from '../components/ui'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ScheduleDay from '../components/ScheduleDay'
import scheduleData from '../../static/data/schedule.json'

const week = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const Schedule = props => {
  return (
    <Layout>
      <SEO title="Class Schedule" />
      <Styled.h1>Class Schedule</Styled.h1>
      <Text>
        We are closed on <Link to="/holidays">certain holidays</Link> and during{' '}
        <Link to="/policies#Weather">hazardous weather</Link>.
      </Text>
      <div sx={styles.grid}>
        {week.map(day => {
          const data = scheduleData[day.toLowerCase()]
          return <ScheduleDay key={day} day={day} data={data} />
        })}
      </div>
    </Layout>
  )
}

const styles = {
  grid: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: ['1fr', 'repeat(5,1fr)'],
    gridAutoRows: 'minmax(300px,auto)',
    gridGap: '2px',
    // gridTemplateRows: ['repeat(7, 100%)','300px 300px'],
  },
}
export default Schedule
