/** @jsx jsx */
import { jsx } from 'theme-ui'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import { Heading, Text } from '../components/ui'
import { TIMEF } from '../utils/schedule'

const ScheduleClass = ({ day, time, data }) => {
  const [hour, minute] = time.split(':')
  const {
    duration = 60,
    title,
    teacher,
    description = 'All Welcome',
    demo,
  } = data
  const startTime = dayjs()
    .hour(hour)
    .minute(minute)
    .second(0)
  const endTime = startTime.add(parseInt(duration), 'minutes')
  const morning = parseInt(hour) < 12
  const classTimeStyle = morning ? {} : styles.nightClass
  return (
    <div sx={{ ...styles.classContainer, ...classTimeStyle }}>
      <div sx={styles.timeContainer}>
        <Heading as="h5">
          {startTime.format(TIMEF)} - {endTime.format(TIMEF)}
        </Heading>
        <Heading as="h5">{title}</Heading>
      </div>
      <div sx={styles.detailsContainer}>
        <Text>{description}</Text>
        <Text sx={styles.teacher}>Teacher: {teacher}</Text>
        {demo ? (
          <Link sx={styles.demo} to="/contact" state={{ day, time }}>
            Schedule a Demo
          </Link>
        ) : null}
      </div>
    </div>
  )
}

const styles = {
  classContainer: {
    backgroundColor: 'day',
    color: 'black',
    width: '100%',
    marginVertical: 5,
  },
  nightClass: {
    backgroundColor: 'night',
    color: 'white',
  },
  timeContainer: {
    // backgroundColor: '#595959',
    width: '100%',
    padding: [1, 2, 2],
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    textAlign: 'center',
  },
  morningContainer: {
    backgroundColor: '#ffead0',
  },
  detailsContainer: {
    padding: [1, 2, 2],
    paddingTop: [0, 0, 0],
    borderBottom: theme => `1px solid ${theme.colors.text}`,
    textAlign: 'center',
    lineHeight: 1.2,
  },
  teacher: {
    fontSize: '85%',
    fontStyle: 'italic',
    marginTop: [1, 1, 2],
    marginBottom: [0, 0, 0],
  },
  demo: {
    fontSize: '75%',
    fontStyle: 'italic',
    marginTop: [0, 0, 1],
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  morningTime: {
    color: 'black',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description: {
    marginVertical: 6,
    textAlign: 'center',
  },
}

export default ScheduleClass
